import 'prop-types';
import modifyAddToCartCardTheme from '@dbh/add-to-cart-card-theme';
import modifyAutocompleteTheme from '@dbh/autocomplete-input-lazy-loader-theme';
import modifyAirSliderTheme from '@dbh/air-slider-theme';
import modifyAnimatedOverlayTheme from '@dbh/animated-overlay-theme';
import modifyButtonTheme from '@dbh/button-theme';
import modifyBookingFormTheme from '@dbh/booking-form-theme';
import buttonWithIconTheme from '@dbh/button-with-icon-theme';
import modifyCarouselTheme from '@dbh/carousel-theme';
import modifyCollapsibleButtonTheme from '@dbh/collapsible-button-theme';
import modifyCollapsibleReduxFormSectionTheme from '@dbh/collapsible-redux-form-section-theme';
import modifyCheckboxTheme from '@dbh/checkbox-theme';
import modifyDatePickerTheme from '@dbh/date-picker-theme';
import modifyDiscountLabelTheme from '@dbh/discount-label-theme';
import modifyDropDownMenuTheme from '@dbh/dropdown-menu-theme';
import modifyEntryContentTheme from '@dbh/entry-content-theme';
import modifyFormFieldFeedbackTheme from '@dbh/form-field-feedback-theme';
import modifyFormSectionTheme from '@dbh/form-section-theme';
import modifyFormGroupTheme from '@dbh/form-group-theme';
import modifyMapTheme from '@dbh/google-map-theme';
import modifyHotelHeaderTheme from '@dbh/hotel-header-theme';
import { modifyCheckInInfoTheme, modifyPackageDetailsTheme } from '@dbh/hotel-package-theme';
import modifyHotelPackageFieldTheme from '@dbh/hotel-package-field-theme';
import modifyHotelPackageStripTheme from '@dbh/hotel-package-strip-theme';
import modifyHighlightedServicesTheme from '@dbh/hotel-highlighted-services-theme';
import modifyDivWithBackgroundTheme from '@dbh/div-with-background-theme';
import modifyIntlFormFieldTheme from '@dbh/intl-form-field-theme';
import lineLimitatorTheme from '@dbh/line-limitator-theme';
import modifyHotelDescriptionTheme from '@dbh/hotel-description-theme';
import modifySearchBoxTheme from '@dbh/search-box-theme';
import modifySearchResultsHeaderTheme from '@dbh/search-results-heading-theme';
import modifyNavbarTheme from '@dbh/navbar-theme';
import modifyPaginatorTheme from '@dbh/paginator-theme';
import modifyRadioButtonFieldTheme from '@dbh/radio-button-field-theme';
import modifyReactDatesTheme from '@dbh/react-dates-field-theme';
import modifyReduxFormSubmitButtonTheme from '@dbh/redux-form-components-theme';
import modifySectionTheme from '@dbh/section-theme';
import priceDetails from '@dbh/price-details-theme';
import modifyRadioWithImagesTheme from '@dbh/radio-button-with-image-theme';
import modifySpinButtonTheme from '@dbh/spin-button-theme';
import modifySelectFieldTheme from '@dbh/select-field-theme';
import modifySmartSectionTheme from '@dbh/smart-section-theme';
import { composeThemeModificationsFunctions } from '@dbh/styled-components-extra';
import modifyTabListTheme from '@dbh/tab-list-theme';
import modifyTappableHotelCardTheme from '@dbh/tappable-hotel-card-theme';
import { Z_INDEX_THEME_KEY, colors, fonts, spacing } from '@dbh/theme-values';
import modifyTotalPriceBarTheme from '@dbh/total-price-bar-theme';
import modifyTextFieldTheme from '@dbh/text-field-theme';
import modifyTextWithIconTheme from '@dbh/text-with-icon-theme';
import modifyToggleTheme from '@dbh/toggle-theme';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import modifyHotelMainServicesTheme from '@dbh/hotel-main-services-theme';
import modifySwitchableViewHotelListMapTheme from '@dbh/switchable-view-hotel-list-map-theme';
import modifyUserBookingCardTheme from '@dbh/user-booking-card-theme';
import modifyDevBarTheme from '@dbh/development-bar-theme';
import modifyShoppingCartPageTheme from '@dbh/shopping-cart-page-theme';
import '@dbh/design-showcase-page-theme';
import modifyHotelUserRatingsTheme from '@dbh/hotel-user-ratings-theme';
import modifyTextAreaTheme from '@dbh/text-area-theme';

/*
 *
 * states: `@dbh/theme`.
 *
 */const focus={button:"\n    outline: 1px dotted;\n    outline: 5px auto -webkit-focus-ring-color;\n  "};var states = {focus};

// eliminate conflicts. If a component has multiple zIndices (maybe in the various
// partials...), the key should be the component name like this:
//  componentName: { entry: 129, anotherEntry: 5512, ..}
const menu={menuItems:10,menuWrapper:9},closeButton=4,navbar=12,hdpPageWrapper=navbar+1,searchBoxFixedWrapper={formWrapper:11,mobileCollapse:10,desktop:{mobileCollapse:9},overlay:8};// The navbar shall be hidden upon scroll, in the `HdpPage`.
// We export this because we are using injectGlobal to inject the styles for
// Google Map's InfoBox (standard JS, doesn't support styled-components). This
// way we can import it as a JS variable in the injectGlobal backticks. Do NOT
// use this method unless there is absolutely no other way with your current
// component.
var zIndices = {[Z_INDEX_THEME_KEY]:{calendar:{picker:15,caret:17},cookiesNoticeBar:10,welcomeBanner:{closeButton},simplePopup:{closeButton},devBar:{fullBarHelper:999,minimizedBarHelper:9999},dropDownItems:{normal:3,arrow:4},entryContent:{entryContentOverlay:13,entryContentWrapper:1,entryContentFixedBar:2},floatingCard:2,header:{wrapperContent:2,tappableSecretZone:5,backgroundImage:-1},map:{infoBoxHover:300,mapWrapper:6,customInfoBox:201},menu,debugWrapper:3,switchableViewHotelListMap:{wrapper:{mobile:{overlayHidden:navbar+1,overlayVisible:searchBoxFixedWrapper.mobileCollapse-1}},mapColumn:1,hidden:-1,visible:2},tab:{tabInner:1},navbar,navBarMenuItem:{wrapperItem:4,basicWrapper:5,logoWrapper:5,menuWrapper:5},geolocationButton:{inline:3},searchBoxFixedWrapper,searchFilters:{transparentSelectWrapper:5,sectionControls:5,footer:1},slideInOverlay:{fixedWrapper:9,contentWrapper:13},loadingOverlay:300,// `css` helper that shows development hints in "dev mode" next to components.
withDevelopmentHints:9998,hotelCard:{overlayedBorder:1,imagesArea:2,overlayedBorderHover:4,scoreLabel:3},totalBar:6,staticMap:{label:1},searchResultsHeader:{stickyRow:10},magicFinderSearchBox:3,daybreakHeroBanner:1,luggageStorageForm:{checkedLuggageBrandFirstIcon:1},hdpPageWrapper}};

// To add your component's theme:
// 1. `import fooComponentTheme from '../components/FooComponent/theme'`.
// 2. add the theme to the end of the 'globalTheme' object below.
// Example: to activate the theme of `FooComponent` add the following to `globalTheme`:
//  fooComponent: fooComponentTheme,
// Then you can access the theme you have just added like this:
//  color: ${props => props.theme.fooComponent.SomeValue}
let globalTheme={colors,fonts,spacing,states,lineLimitatorTheme,...buttonWithIconTheme,...zIndices,...priceDetails};const globalThemeFunctions=[modifyDivWithBackgroundTheme,modifyAddToCartCardTheme,modifyBookingFormTheme,modifyDatePickerTheme,modifyEntryContentTheme,modifyHotelDescriptionTheme,modifyHotelHeaderTheme,modifyDevBarTheme,modifySwitchableViewHotelListMapTheme,modifyHotelMainServicesTheme,modifyCheckInInfoTheme,modifyPackageDetailsTheme,modifyHotelPackageFieldTheme,modifyHotelPackageStripTheme,modifyHighlightedServicesTheme,modifyMapTheme,modifyNavbarTheme,modifyRadioWithImagesTheme,modifyReduxFormSubmitButtonTheme,modifySearchBoxTheme,modifySearchResultsHeaderTheme,modifyTabListTheme,modifyTappableHotelCardTheme,modifyTotalPriceBarTheme,modifyShoppingCartPageTheme,modifyAirSliderTheme,modifyAutocompleteTheme,modifyCarouselTheme,modifyCheckboxTheme,modifyCollapsibleButtonTheme,modifyCollapsibleReduxFormSectionTheme,modifyDropDownMenuTheme,modifyFormFieldFeedbackTheme,modifyFormSectionTheme,modifyPaginatorTheme,modifyRadioButtonFieldTheme,modifyReactDatesTheme,modifySectionTheme,modifySelectFieldTheme,modifySmartSectionTheme,modifySpinButtonTheme,modifyTextFieldTheme,modifyToggleTheme,modifyTextWithIconTheme,modifyButtonTheme,modifyUserBookingCardTheme,modifyAnimatedOverlayTheme,modifyFormGroupTheme,modifyIntlFormFieldTheme,modifyDiscountLabelTheme,modifyHotelUserRatingsTheme,modifyTextAreaTheme];const createStyledComponentsTheme=withConformsTo("createStyledComponentsTheme",[],(a,b)=>{let c=a||globalTheme;b&&(c={...c,colors:b});const d=composeThemeModificationsFunctions(...globalThemeFunctions)(c);return d});

export { createStyledComponentsTheme as default };
