import { getFormValues, change, initialize, reset, actionTypes } from 'redux-form/immutable';
import { push, LOCATION_CHANGE } from 'react-router-redux';
import { select, put, call, take, all, fork, takeLatest, takeEvery, spawn } from 'redux-saga/effects';
import { SEARCH_BOX_INPUT_NAMES, getDefaultPackageTypeOfMacroCategory, makeSelectSearchBoxInitialValues, makeSelectSearchCategoriesTreeElements, getMacroCategoryIdFromIdProductsQuery, searchBoxAndSearchFiltersSharedHandlers, SEARCHBOX_SUCCESSFULLY_SUBMITTED } from '@dbh/search-box-redux';
import { searchFiltersFormName, searchBoxFormName } from '@dbh/redux-form-constants';
import { createMemoizedSelectorBasedOnInputAndOutput, createSimpleSelector } from '@dbh/reselect-extra';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { memoize, keyIn } from '@dbh/lodash-extra';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { Map, List, is, fromJS } from 'immutable';
import { makeSelectPreviousLocationHref, makeSelectLocationHrefWithoutHash, makeSelectLocationHash } from '@dbh/routing-redux';
import { searchFiltersEntryContentName } from '@dbh/entry-content-names';
import { entryContentHidden, entryContentShown } from '@dbh/with-entry-content';
import 'prop-types';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';
import 'invariant';
import { makeTimeSlotOptions } from '@dbh/select-field';
import { DEFAULT_ID_MACRO_CATEGORY, ADMIN_PAGE_REFERENCES } from '@dbh/admindev-constants';
import { makeSelectSearchContext, makeSelectCurrentHashPageReference, makeSelectFindWebpageRouteByHash } from '@dbh/webpages-redux';
import { searchContextImmutablePropType } from '@dbh/search-context-types';
import { conformsTo } from '@dbh/with-conforms-to-extra';
import { getDateId } from '@dbh/dates';
import { createSelector } from 'reselect';

const REDUX_AND_SAGA_KEY=searchFiltersFormName;// @TODO: Investigate and remove the `NULL_FILTER_VALUE` if not needed.
// `NULL_FILTER_VALUE` is a special constant that will be used in the filters
// `SelectField` options, to indicate a `null` value. We can't use `null` as a
// value because it would be cast to string.
const NULL_FILTER_VALUE="__DBH_NULL_FILTER_VALUE";const SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES=Map({priceMin:"__DBH_NULL_FILTER_VALUE",priceMax:"__DBH_NULL_FILTER_VALUE",starsMin:"__DBH_NULL_FILTER_VALUE",starsMax:"__DBH_NULL_FILTER_VALUE",checkIn:"__DBH_NULL_FILTER_VALUE",checkOut:"__DBH_NULL_FILTER_VALUE",distance:2e4,idServices:List(),acceptsDiscountCodes:null});// `\u2605` is the `unicode` for `★`.
const STAR_SYMBOL="\u2605";const SEARCH_FILTERS_TIME_CHECKIN_OPTIONS_SET="@dbh/search-filters/SEARCH_FILTERS_TIME_CHECKIN_OPTIONS_SET";const SEARCH_FILTERS_TIME_CHECKOUT_OPTIONS_SET="@dbh/search-filters/SEARCH_FILTERS_TIME_CHECKOUT_OPTIONS_SET";const SEARCH_FILTERS_FORM_HIDDEN="@dbh/search-filters/SEARCH_FILTERS_FORM_HIDDEN";const translationIds={title:"components.SearchFilter.title",resultsCount:"components.SearchFilter.results_count",priceRange:"components.SearchFilter.price_range",proximity:"components.SearchFilter.proximity",guestRating:"components.SearchFilter.guest_rating",occupancyTime:"components.SearchFilter.occupancy_time",checkIn:"components.SearchFilter.check_in",checkOut:"components.SearchFilter.check_out",stars:"components.SearchFilter.stars",orderBy:"components.SearchFilter.order",saveBtn:"components.SearchFilter.tappable_save_and_apply",cancelBtn:"components.SearchFilter.tappable_cancel",from:"components.FormSearchFilters.from",to:"components.FormSearchFilters.to",rangeMin:"components.SearchFilter.range_min",rangeMax:"components.SearchFilter.range_max",services:"components.SearchFilter.services",servicesCount:"components.SearchFilter.services_count",helperText:{fromToRange:"components.SearchFilter.from_rangemin_to_rangemax",fromRange:"components.SearchFilter.from_rangemin",toRange:"components.SearchFilter.to_rangemax"},occupancyHelperText:{fromToRange:"components.SearchFilter.from_checkin_to_checkout",fromRange:"components.SearchFilter.from_checkin",toRange:"components.SearchFilter.to_checkout"},occupancyNotSet:"components.SearchFilter.not_set",all:"global.all",selectHotelServices:"components.SearchFilter.select_hotel_services",currentDistance:"components.SearchFilter.current_proximity",date:"components.SearchFilter.date",macroCategory:"components.SearchFilter.macrocategory"};// `DISABLED_FILTER_VALUE` is a special constant that will be used in the filters
// `SelectField` options, to indicate a `disabled` value. We can't use `null` as a
// value because it would be cast to string.
const DISABLED_FILTER_VALUE="__DBH_DISABLED_FILTER_VALUE";

/**
 * Select the `SearchFilters` state.
 * @param {Immutable.Map} state .
 * @return {Immutable.Map?} .
 */const selectSearchFiltersState=a=>a?a.get(REDUX_AND_SAGA_KEY):void 0;var selectSearchFiltersState$1 = selectSearchFiltersState;

/**
 * Returns the filters initial values, mergin the defaults with the searchbox
 * initial values.
 * @param {Immutable.Map?} searchBoxInitialValues .
 * @return {Immutable.Map} .
 */const createSearchFiltersInitialValues=memoize(withConformsTo("createSearchFiltersInitialValues",[],(a,b)=>{if(!a)return;const c=a.get(SEARCH_BOX_INPUT_NAMES.MACRO_CATEGORY),d=getDefaultPackageTypeOfMacroCategory(b,c),e=a.set(SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES,d);return SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES.merge(e.filter(keyIn(SEARCH_BOX_INPUT_NAMES.SELECT_WHEN,SEARCH_BOX_INPUT_NAMES.SELECT_WHEN_DATE,SEARCH_BOX_INPUT_NAMES.CALENDAR_VISIBLE,SEARCH_BOX_INPUT_NAMES.MACRO_CATEGORY,SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES)))}));var createSearchFiltersInitialValues$1 = createSearchFiltersInitialValues;

/**
 * Returns a selector that returns the default intial values of the
 * `SearchFilters` form.
 * @return {Immutable.Map} Intial values (`redux-form` concept) of the
 * `SearchFilters` form.
 */const makeSelectSearchFiltersDefaultInitialValues=()=>createMemoizedSelectorBasedOnInputAndOutput(makeSelectSearchBoxInitialValues(),makeSelectSearchCategoriesTreeElements(),createSearchFiltersInitialValues$1);var makeSelectSearchFiltersDefaultInitialValues$1 = makeSelectSearchFiltersDefaultInitialValues;

/**
 * Returns a selector that returns `true` if the search filters values are
 * equal to their default initial values. This selector uses the form values
 * and will return `false` if the form values are not in the `redux` store.
 * Therefore, it can only be used only when the form is connected to the store.
 * @return {boolean} Are the filter values equal to the default initial values.
 */const makeSelectSearchFiltersFormValuesEqualDefaultInitialValues=()=>createSelector(getFormValues(searchFiltersFormName),makeSelectSearchFiltersDefaultInitialValues$1(),is);var makeSelectSearchFiltersFormValuesEqualDefaultInitialValues$1 = makeSelectSearchFiltersFormValuesEqualDefaultInitialValues;

/**
 * Given the `searchContext` returns the current number of `searchFilters` applied.
 * @param {Immutable.Map?} searchContext Current value of the `SearchContext`.
 * @return {number} How many search filters applied.
 */const computeNumberOfSearchFiltersApplied=withConformsTo("computeNumberOfSearchFiltersApplied",[],(a,b)=>{if(!a)return 0;const c=a.get("filters");let d=0;if(c&&0<c.size){const a=c.count((a,b)=>"idServices"!==b&&a!==SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES.get(b)),b=c.get("idServices"),e=b?b.size:0;d+=a+e;}const e=a.get("idProductsQuery");if(e&&b){const a=getMacroCategoryIdFromIdProductsQuery(e,b),c=getDefaultPackageTypeOfMacroCategory(b,a);e!==c&&(d+=1);}return d});var computeNumberOfSearchFiltersApplied$1 = computeNumberOfSearchFiltersApplied;

/**
 * Returns a selector that returns the current amount of search filters applied.
 * @return {number} How many search filters applied.
 */const makeSelectNumberOfSearchFiltersApplied=()=>createSelector(makeSelectSearchContext(),makeSelectSearchCategoriesTreeElements(),computeNumberOfSearchFiltersApplied$1);var makeSelectNumberOfSearchFiltersApplied$1 = makeSelectNumberOfSearchFiltersApplied;

/**
 * Converts a `searchContext` that the search `API` accepts and `SearchBox`
 * values to an `Immutable.Map` containing the corresponding form values for
 * the search filters form. This is used when we want to translate values from
 * the query inside the `URL`, to values our form understands.
 * @see `searchContextImmutablePropType` in `@dbh/hotels-types`.
 * @param {Immutable.Map?} searchContext If it is `undefined`, it is possible
 * that we are in a page whose `webpages?url=` `API` returns `NotFoundPage`
 * or returns a redirect. We always call the `APIs` in parallel; we don't wait
 * for the `webpages` `API` to return to call the other `APIs` (for performance).
 * Therefore these functions must be coded accordingly and be "resistant".
 * @param {Immutable.Map?} searchBoxFormValues .
 * @return {Immutable.Map} The form values corresponding to the `searchContext`
 * and the passed `SearchBox` form values.
 */const createSearchFiltersValues=memoize(withConformsTo("createSearchFiltersValues",[],(a,b)=>{const c=a&&a.get("filters")||Map(),d=a?a.get("searchDate"):getDateId(new Date);// `SearchBox` fields that are used by the `SearchFilters` form and rendered
// in the filter modal.
let e=b&&b.filter(keyIn(SEARCH_BOX_INPUT_NAMES.SELECT_WHEN,SEARCH_BOX_INPUT_NAMES.SELECT_WHEN_DATE,SEARCH_BOX_INPUT_NAMES.CALENDAR_VISIBLE,SEARCH_BOX_INPUT_NAMES.MACRO_CATEGORY,SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES));e||(e=Map({[SEARCH_BOX_INPUT_NAMES.SELECT_WHEN]:d,[SEARCH_BOX_INPUT_NAMES.SELECT_WHEN_DATE]:d,[SEARCH_BOX_INPUT_NAMES.CALENDAR_VISIBLE]:!1,[SEARCH_BOX_INPUT_NAMES.MACRO_CATEGORY]:DEFAULT_ID_MACRO_CATEGORY}));let f=c;const g=c.get("checkOut");"23:59"===g&&(f=c.set("checkOut","24:00"));const h=SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES.mergeDeep(fromJS(f),e);return h}));var createSearchFiltersValues$1 = createSearchFiltersValues;

/**
 * Returns a selector that returns the current initial values of the
 * `SearchFilters` form. The values returned by this selector may not be the
 * same as the ones returned by `makeSelectSearchFiltersDefaultInitialValues`.
 * We use these two selectors to understand if we can reset the `SearchFilters`
 * form to default state.
 * @return {Immutable.Map} Current intial values of the `SearchFilters` form.
 */const makeSelectSearchFiltersInitialValues=()=>createMemoizedSelectorBasedOnInputAndOutput(makeSelectSearchContext(),makeSelectSearchBoxInitialValues(),createSearchFiltersValues$1);var makeSelectSearchFiltersInitialValues$1 = makeSelectSearchFiltersInitialValues;

/**
 * Returns a selector that returns the `checkInOptions` for `SearchFilters`.
 * @return {Immutable.Map?} .
 */const makeSelectSearchFiltersTimeOptionsCheckin=()=>createSimpleSelector(selectSearchFiltersState$1,["time","checkInOptions"]);var makeSelectSearchFiltersTimeOptionsCheckin$1 = makeSelectSearchFiltersTimeOptionsCheckin;

/**
 * Returns a selector that returns the `checkOutOptions` for `SearchFilters`.
 * @return {Immutable.Map?} .
 */const makeSelectSearchFiltersTimeOptionsCheckout=()=>createSimpleSelector(selectSearchFiltersState$1,["time","checkOutOptions"]);var makeSelectSearchFiltersTimeOptionsCheckout$1 = makeSelectSearchFiltersTimeOptionsCheckout;

/**
 * Returns a selector that returns `true` if the filters modal must be visible.
 * @return {boolean} .
 */const makeSelectIsSearchFiltersFormVisible=()=>createSelector(makeSelectCurrentHashPageReference(),a=>a===ADMIN_PAGE_REFERENCES.SearchFilters);var makeSelectIsSearchFiltersFormVisible$1 = makeSelectIsSearchFiltersFormVisible;

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResultPayloadWithError} SagaEventResultPayloadWithError
 * @typedef {import('@dbh/redux-saga-extra').SagaEventFormMetaResult} SagaEventFormMetaResult
 *//**
 * Resets the filters form. We need to do this on every new search, by business requirements.
 * In order to correctly reset the form we need to first initialize it with the default values,
 * because it might be initialized on other values (@example opening a link that includes filter values).
 * We use `initialize` + `reset` because it doesn't trigger any `onChange` handlers that might be set
 * on the form to handle user interactions.
 * @see {@link https://redux-form.com/7.4.2/docs/api/actioncreators.md}
 * @yields {SagaEventResultPayloadWithError|SagaEventFormMetaResult} .
 */function*resetFilters(){const a=yield select(makeSelectSearchFiltersDefaultInitialValues$1());yield put(change(searchBoxFormName,SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES,a.get(SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES))),yield put(initialize(searchFiltersFormName,a,!0)),yield put(reset(searchFiltersFormName));}

const searchFiltersCheckinOptionsSet=a=>({type:SEARCH_FILTERS_TIME_CHECKIN_OPTIONS_SET,payload:{options:a}});const searchFiltersCheckoutOptionsSet=a=>({type:SEARCH_FILTERS_TIME_CHECKOUT_OPTIONS_SET,payload:{options:a}});/**
 * Dispatched when the search filters entry content is hidden.
 * @param {boolean?} goBackToPreviousLocationHref If `true`, don't just remove
 * the hash and stay on the current page, but go back to the previous page,
 * including its hash, if it had one.
 * @return {Object} .
 */const searchFiltersFormHidden=withConformsTo("searchFiltersFormHidden",[],a=>({type:SEARCH_FILTERS_FORM_HIDDEN,payload:{goBackToPreviousLocationHref:a}}));const useSearchFiltersFormHidden=createUseBoundEventCreator(searchFiltersFormHidden);

const unsetOption=Map({id:NULL_FILTER_VALUE,label:translationIds.occupancyNotSet,intlFormatter:!0}),makeTimeOptions=(a,b)=>makeTimeSlotOptions(60,a,b,{timeFormat:"kk:mm",nextDayLabel:"\u207A\xB9",midnightIsStartOfDay:!1}).unshift(unsetOption),defaultTimeConfig=Map({optionsCheckIn:makeTimeOptions("07:00","23:00"),optionsCheckOut:makeTimeOptions("10:00","24:00"),minOffsetCheckout:1}),timeConfigs=List([Map({optionsCheckIn:makeTimeOptions("07:00","14:00"),optionsCheckOut:makeTimeOptions("10:00","18:00"),minOffsetCheckout:1}),Map({optionsCheckIn:makeTimeOptions("15:00","21:00"),optionsCheckOut:makeTimeOptions("16:00","24:00"),minOffsetCheckout:1,maxOffsetCheckout:6}),Map({optionsCheckIn:makeTimeOptions("22:00","23:00"),optionsCheckOut:makeTimeOptions("23:00","09:00"),minOffsetCheckout:1})]);/**
 * Create time slot options.
 * @param {string} start Time in hours and minutes.
 * @param {string} end Time in hours and minutes.
 * @return {Immutable.Map} Time options.
 */// This options are grouped per available ranges that a customer can select.
// Per business requirements, for the `checkIn` field, the `defaultTimeConfig`
// options are always shown. In this list, the `checkIn` options are used to
// identify the set of `checkOut` options to show, i.e. if a customer selects
// the `15:00` `checkIn` option, the group which contains that `checkIn` option
// is selected and the respective `checkOut` options should be shown.
/**
 * Returns the default time config, it could be used when none of the options
 * are selected.
 * @return {Immutable.Map} `defaultTimeConfig`.
 */const getDefaultTimeConfig=()=>defaultTimeConfig;/**
 * Select the correct time config and filter its options based on the
 * `checkInValue`.
 * @param {string} checkInValue .
 * @return {Immutable.Map} Time config with filtered options.
 */const getTimeConfig=a=>{if(!a||a===NULL_FILTER_VALUE)return defaultTimeConfig;// Find the `config` with the `optionsCheckIn` containing the `checkInValue`.
const b=timeConfigs.find(b=>b.get("optionsCheckIn").find(b=>b.get("id")===a)),c=b.get("optionsCheckOut").filter(a=>a!==unsetOption);// For convenience remove the `unsetOption` and add it to the
// `filteredOptions`.
let d=c;// The available `checkOut` options must be "later" than the `checkInValue`
// and respect the `minOffsetCheckout`.
const e=c.findIndex(b=>b.get("id")===a);if(-1<e){const a=b.get("minOffsetCheckout")||0;d=c.slice(e+a);}// Limit the available `checkOut` options by the `maxOffsetCheckout`.
const f=b.get("maxOffsetCheckout");return f&&(d=d.slice(0,f)),d=d.unshift(unsetOption),b.set("optionsCheckOut",d)};

/**
 * Calculates the available checkout value based on current the selected checkin
 * and checkout values and `timeConfig`.
 * @param {string} checkInValue Currently selected value.
 * @param {string} checkOutValue Currently selected value.
 * @param {Immutable.Map} timeConfig Currently selected config.
 * @return {string} New or same checkout value.
 */const getCheckoutValue=(a,b,c)=>{const d=c.get("optionsCheckOut"),e=c.get("minOffsetCheckout"),f=d.findIndex(b=>b.get("id")===a),g=d.findIndex(a=>a.get("id")===b),h=f+e;// Get the indexes of both `checkInValue` and `checkOutValue` so the
// `checkOutValue` can be adjusted based on the `checkInValue`.
// The currently selected `checkInValue` is set and in range of the
// `optionsCheckOut`, adjust the `checkOutValue` if needed.
return 0<f&&g<h?d.get(h).get("id"):1>g&&a!==NULL_FILTER_VALUE?d.get(1).get("id"):b;// The currently selected `checkOutValue` is unset or unavailable in the
// current `optionsCheckOut` but the `checkIn` is set and its value is not
// present in the `checkOut` options, we want to set `checkOut` to the first
// available option.
// The currently selected `checkOutValue` doesn't conflict with the currently
// selected `checkInValue` and it's available in the current
// `optionsCheckOut`.
};var getCheckoutValue$1 = getCheckoutValue;

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 *//**
 * Update `SearchFilters` `checkIn` and `checkOut` time options.
 * @yields {SagaEventResult} .
 */function*updateTimeOptions(){// Get current values in the `redux-form` reducer.
const a=yield select(getFormValues(searchFiltersFormName)),b=a.get("checkOut"),c=a.get("checkIn"),d=getTimeConfig(c),e=getCheckoutValue$1(c,b,d);// Calculate the available options.
// Reevaluate the selected `checkoutValue`.
if(e!==b){const a=change(searchFiltersFormName,"checkOut",e);yield put(a);}const f=d.get("optionsCheckOut");yield put(searchFiltersCheckoutOptionsSet(f));}

function*resetFiltersToLatestState(){yield put(reset(searchFiltersFormName)),yield call(updateTimeOptions);}

function*handleFiltersHidden(a){const{payload:{goBackToPreviousLocationHref:b}}=a,c=yield select(b?makeSelectPreviousLocationHref():makeSelectLocationHrefWithoutHash());// Keep this fired before calling the push event else it will not go to
// previous location with hash.
yield put(entryContentHidden(searchFiltersEntryContentName)),yield put(push(c)),yield call(resetFiltersToLatestState);}

function*handleFiltersShown(){yield put(entryContentShown(searchFiltersEntryContentName)),yield call(updateTimeOptions);}

function*openFiltersWhenHashIsSearchFiltersPage(a){if(a){const b=yield select(makeSelectFindWebpageRouteByHash(a));if(b){const a=b.get("reference");a===ADMIN_PAGE_REFERENCES.SearchFilters&&(yield call(handleFiltersShown));}}}

function handleLocationChange(a){let{payload:{hash:b}}=a;return function*(){yield call(openFiltersWhenHashIsSearchFiltersPage,b);}()}

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 *//**
 * The `checkIn` and `checkOut` time options are dynamically adjusted based on the
 * current values.
 * @param {Object} event .
 * @yields {SagaEventResult?} .
 */function*handleFilterTime(a){const{meta:{form:b,field:c}}=a;b!==searchFiltersFormName||"checkIn"===c&&(yield call(updateTimeOptions));}

const{INITIALIZE}=actionTypes;function*checkFiltersVisibilityOnSagaInjection(){const a=yield select(makeSelectLocationHash());// Wait for the first `redux-form` `INITIALIZE` event, otherwise we don't
// have the default values in the state and the initialization will fail.
yield take(a=>a.type===INITIALIZE&&a.meta&&a.meta.form===searchFiltersFormName),yield call(openFiltersWhenHashIsSearchFiltersPage,a);}

const{CHANGE}=actionTypes;// Root saga.
function*searchFiltersRootSaga(){yield all([fork(searchBoxAndSearchFiltersSharedHandlers,REDUX_AND_SAGA_KEY),takeLatest(SEARCHBOX_SUCCESSFULLY_SUBMITTED,resetFilters),takeLatest(SEARCH_FILTERS_FORM_HIDDEN,handleFiltersHidden),takeLatest(LOCATION_CHANGE,handleLocationChange),takeEvery(CHANGE,handleFilterTime),spawn(checkFiltersVisibilityOnSagaInjection)]);}

const timeConfig=getDefaultTimeConfig(),initialState=Map({time:Map({checkInOptions:timeConfig.get("optionsCheckIn"),checkOutOptions:timeConfig.get("optionsCheckOut")})}),searchFiltersReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case SEARCH_FILTERS_TIME_CHECKIN_OPTIONS_SET:{const{payload:{options:c}}=b;return a.setIn(["time","checkInOptions"],c)}case SEARCH_FILTERS_TIME_CHECKOUT_OPTIONS_SET:{const{payload:{options:c}}=b;return a.setIn(["time","checkOutOptions"],c)}default:return a}};var searchFiltersReducer$1 = searchFiltersReducer;

/**
 * Create the price options.
 * @example
 * ```js
 * [
 *   {
 *     id: '0',
 *     label: '0',
 *     intlFormatter: false,
 *   },
 *   {
 *     id: '25',
 *     label: '25',
 *     intlFormatter: false,
 *   },
 *   [...]
 *   {
 *     id: NULL_FILTER_VALUE,
 *     label: '200+',
 *     intlFormatter: false,
 *   },
 * ];
 * ```
 * @param {number} minPrice The minimum value for the price.
 * @param {number} maxPrice The maximum value for the price.
 * @param {number} step The increment between the price options.
 * @typedef {{min: Immutable.List, max: Immutable.List}} MinMaxPriceOptions
 * @return {MinMaxPriceOptions} Contains a min and a max price options.
 */const createMinMaxPriceOptions=memoize((a,b,c)=>{let d=List(),e=List();for(let f,g=a;g<=b+c;g+=c)switch(f=Map({id:g,comparableValue:g,label:g+"",intlFormatter:!1}),g){case a:f=f.set("id",NULL_FILTER_VALUE),d=d.push(f);break;case b+c:f=f.merge({id:NULL_FILTER_VALUE,label:b+"+"}),e=e.push(f);break;default:d=d.push(f),e=e.push(f);}return {min:d,max:e}});var createMinMaxPriceOptions$1 = createMinMaxPriceOptions;

/**
 * Stars options.
 * @example
 * ```js
 * [
 *   {
 *     id: '1',
 *     label: '★',
 *     intlFormatter: false,
 *   },
 *   {
 *     id: '2',
 *     label: '★★',
 *     intlFormatter: false,
 *   },
 *   [...]
 *   {
 *     id: NULL_FILTER_VALUE,
 *     label: '★★★★★',
 *     intlFormatter: false,
 *   },
 * ];
 * ```
 * @param {number} minStars The minimum value for the stars.
 * @param {number} maxStars The maximum value for the stars.
 * @param {number} step The increment between the stars options.
 * @typedef {{min: Immutable.List, max: Immutable.List}} MinMaxStarOptions
 * @return {MinMaxStarOptions} Contains a min and a max star options.
 */const createMinMaxStarsOptions=memoize(function(a,b,c){void 0===a&&(a=1),void 0===b&&(b=5),void 0===c&&(c=1);let d=List(),e=List();for(let f,g=a;g<=b;g+=c)switch(f=Map({id:g,comparableValue:g,label:STAR_SYMBOL.repeat(g),intlFormatter:!1}),g){case a:e=e.push(f),f=f.set("id",NULL_FILTER_VALUE),d=d.push(f);break;case b:d=d.push(f),f=f.set("id",NULL_FILTER_VALUE),e=e.push(f);break;default:d=d.push(f),e=e.push(f);}return {min:d,max:e}});var createMinMaxStarsOptions$1 = createMinMaxStarsOptions;

/**
 * Converts an `Immutable.Map` of `@dbh/search-filters-form` values to a part of the
 * `searchContext` that the search API accepts.
 * @see `searchContextImmutablePropType` in `@dbh/hotels-types`.
 * @param {Immutable.Map} formSearchFiltersValues The form values.
 * @param {Immutable.Map} searchCategoriesTreeElements `macroCategory` field
 * options.
 * @return {Immutable.Map} The partial search context corresponding to the form values.
 */const createPartialSearchContextFromSearchFiltersFormValues=memoize(withConformsTo("createPartialSearchContextFromSearchFiltersFormValues",["formSearchFiltersValues",ImmutablePropTypes.map.isRequired],a=>{// We want to send `null` in place of the values that we don't want to send to
// the server. Unfortunately `null` is not a valid option value, so we're using
// a placeholder stored in the constant `NULL_FILTER_VALUE`.
// Here we replace the placeholder with the `null` value so that they can overwrite
// the previous values and then be ignored by the saga in a later step.
const b=a.reduce((a,b,c)=>"distance"===c&&b===SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES.get("distance")?a.set(c,null):"checkOut"===c&&"24:00"===b?a.set(c,"23:59"):b===NULL_FILTER_VALUE?a.set(c,null):[SEARCH_BOX_INPUT_NAMES.SELECT_WHEN,SEARCH_BOX_INPUT_NAMES.SELECT_WHEN_DATE,SEARCH_BOX_INPUT_NAMES.CALENDAR_VISIBLE,SEARCH_BOX_INPUT_NAMES.MACRO_CATEGORY,SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES].includes(c)?a:a.set(c,b),Map()),c=a.get(SEARCH_BOX_INPUT_NAMES.PACKAGE_TYPES),d=a.get(SEARCH_BOX_INPUT_NAMES.SELECT_WHEN),e=fromJS({filters:b,searchDate:d,idProductsQuery:c});return conformsTo({partialSearchContext:e},{partialSearchContext:searchContextImmutablePropType},"createPartialSearchContextFromSearchFiltersFormValues"),e}));var createPartialSearchContextFromSearchFiltersFormValues$1 = createPartialSearchContextFromSearchFiltersFormValues;

export { DISABLED_FILTER_VALUE, NULL_FILTER_VALUE, REDUX_AND_SAGA_KEY, SEARCH_FILTERS_EXCLUSIVE_INITIAL_VALUES, SEARCH_FILTERS_FORM_HIDDEN, SEARCH_FILTERS_TIME_CHECKIN_OPTIONS_SET, SEARCH_FILTERS_TIME_CHECKOUT_OPTIONS_SET, STAR_SYMBOL, computeNumberOfSearchFiltersApplied$1 as computeNumberOfSearchFiltersApplied, createMinMaxPriceOptions$1 as createMinMaxPriceOptions, createMinMaxStarsOptions$1 as createMinMaxStarsOptions, createPartialSearchContextFromSearchFiltersFormValues$1 as createPartialSearchContextFromSearchFiltersFormValues, createSearchFiltersInitialValues$1 as createSearchFiltersInitialValues, createSearchFiltersValues$1 as createSearchFiltersValues, makeSelectIsSearchFiltersFormVisible$1 as makeSelectIsSearchFiltersFormVisible, makeSelectNumberOfSearchFiltersApplied$1 as makeSelectNumberOfSearchFiltersApplied, makeSelectSearchFiltersDefaultInitialValues$1 as makeSelectSearchFiltersDefaultInitialValues, makeSelectSearchFiltersFormValuesEqualDefaultInitialValues$1 as makeSelectSearchFiltersFormValuesEqualDefaultInitialValues, makeSelectSearchFiltersInitialValues$1 as makeSelectSearchFiltersInitialValues, makeSelectSearchFiltersTimeOptionsCheckin$1 as makeSelectSearchFiltersTimeOptionsCheckin, makeSelectSearchFiltersTimeOptionsCheckout$1 as makeSelectSearchFiltersTimeOptionsCheckout, searchFiltersCheckinOptionsSet, searchFiltersCheckoutOptionsSet, searchFiltersFormHidden, searchFiltersReducer$1 as searchFiltersReducer, searchFiltersRootSaga as searchFiltersSaga, selectSearchFiltersState$1 as selectSearchFiltersState, translationIds, useSearchFiltersFormHidden };
