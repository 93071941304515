import _noop from 'lodash/noop';
import _once from 'lodash/once';
import PropTypes from 'prop-types';
import { getIsDbhStaff, createDocumentVisibilityChangeWatcher, getStorageItem } from '@dbh/dom';
import { isServerSideRendering } from '@dbh/environment';
import { getActiveConsentGroups, getIsAnalyticsStorageConsentGiven } from '@dbh/cookie-consent-utils';
import '@dbh/throw-in-server-side-rendering';
import { ensureGtag, extractConnectionDataFromNavigator } from '@dbh/tracking-gtag-shared-with-browser';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { useCallback } from 'react';
import { useMakeSelect } from '@dbh/reselect-extra';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import { searchContextToGaEventParameters } from '@dbh/analytics';
import { makeSelectCurrentRouterState, makeSelectCountry } from '@dbh/routing-redux';
import { selectLocationOriginFromServer, makeSelectComputedSSrBreakpointsForRendering, makeSelectGeneratedTrackingIds } from '@dbh/ssr-data-redux';
import { makeSelectIsUserSignedIn } from '@dbh/user-area-redux';
import { makeSelectCurrentWebpageRouteReference, makeSelectCurrentWebpageRouteTemplateCode, makeSelectIsStaticPage, makeSelectTemplateCode, makeSelectTitle, makeSelectSearchContext } from '@dbh/webpages-redux';
import { makeSelectDaybreakHeroData } from '@dbh/daybreak-hero-redux';
import { select } from 'redux-saga/effects';
import 'common-tags';
import '@dbh/create-custom-react-prop-type';
import 'react-immutable-proptypes';
import '@dbh/generic-types';

const canLog=()=>getIsDbhStaff();var canLog$1 = canLog;

/*
 *
 * Constants: `analyticsEventSent`.
 *
 */const QUEUED_EVENTS=[];

const sendEventWithGtagAndLogs=withConformsTo("sendEventWithGtagAndLogs",[],a=>{let{eventPastTense:b,stgtmGa4eventData:c}=a;const d=canLog$1();window.gtag("event",b,c),d&&console.info("[dbh][gtag][event][TO SGTM]",b,c||"");});var sendEventWithGtagAndLogs$1 = sendEventWithGtagAndLogs;

let sendingQueuedEvents=!1;const handleVisibilityChanged=withConformsTo("handleVisibilityChanged",[],a=>{let{sentOnDomVisibilityEvent:b,visibilityState:c}=a;if(!(sendingQueuedEvents||"hidden"!==c)){sendingQueuedEvents=!0;const a=QUEUED_EVENTS.length;// If you want to see `firedOnDomEvent` in the `GA4` reports
// without using `Big Query`, you have to create it as a custom dimension.
// But there are a limited number of them, so first consult with the analytics
// team, to ask if it's needed or useful for them.
QUEUED_EVENTS.forEach(a=>{const{stgtmGa4eventData:c}=a;sendEventWithGtagAndLogs$1({...a,stgtmGa4eventData:{...c,sentOnDomVisibilityEvent:b}});}),QUEUED_EVENTS.splice(0,a),sendingQueuedEvents=!1;}});var handleVisibilityChanged$1 = handleVisibilityChanged;

const eventNamePropType=_noop;/**
 * Event names must start with a letter. Use only letters, numbers, and
 * underscores. Don't use spaces.
 * @see {@link https://support.google.com/analytics/answer/13316687?hl=en#:~:text=Event%20names%20can%20include%20English,letters%2C%20numbers%2C%20and%20underscores}
 */var eventNamePropType$1 = eventNamePropType;

// All booleans that are not native `GA4` properties must be a boolean string.
const analyticsEventImmutablePropType=_noop;var analyticsEventImmutablePropType$1 = analyticsEventImmutablePropType;

const keyIn=a=>(b,c)=>a.includes(c),doStuffOnceTheFirstTimeFunctionIsCalled=_once(()=>{createDocumentVisibilityChangeWatcher(handleVisibilityChanged$1,!0);}),createAnalyticsEventSent=()=>{/**
   * Send a `GA4` event with `gtag`.
   * @param {Immutable.Map} analyticsEvent The event in "our" format.
   * @param {Object?} options .
   * @param {boolean?} options.sendOnVisibilityChange .
   * @return {Promise} .
   * @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs/events}
   */const a=withConformsTo("analyticsEventSent",[],(a,b)=>new Promise(c=>{ensureGtag(),doStuffOnceTheFirstTimeFunctionIsCalled();const d=getActiveConsentGroups(),e=getIsAnalyticsStorageConsentGiven(d);// `event_callback` is firing twice because of our gtag configuration. Use this
// boolean to check if this is the first time the callback is firing, and only in
// that case resolve the promise.
let f=!1;const g=a.get("eventPastTense"),h=a.get("transactionId"),i=a.get("items"),j=a.get("coupon"),{sendOnVisibilityChange:k}=b||{};let l={...(k?{}:{event_callback:()=>{f||(f=!0,c());}}),// Do not create a `dbhEventTimestamp` custom dimension, because
// it might lead to severe cardinality issues in the data. Do the
// granular analysis with `Google BigQuery`. `GA4` stores this
// value as `DATETIME` (`UTC` timezone, `YYYY-MM-DD HH:MM:SS`) inside
// `Google BigQuery`.
// @see {@link https://support.google.com/analytics/answer/12226705?hl=en}
dbhEventTimestamp:new Date().toISOString(),event_category:a.get("eventCategory"),event_label:a.get("eventLabel"),non_interaction:a.get("nonInteraction"),dbhVersionCode:"2025-01-16T14_48_05-efb479c8536e2481004cd382dca677dfccc3fd85-production-bitbucket-8628",...a.filter(keyIn(["value","method","currency","search_term",// Search context: start.
// We have asked the analytics team to calculate unique values
// for the following keys, from the `GA4` data.
// The unique values should be below the threshold of 500,
// with `idSearchEntity` coming close when the traffic is very
// high.
// If our traffic rises a lot, the calculation should be made
// again, to decide whether to "archive" the dimensions in the
// `GA4` dashboard (before doing that read the documentation
// carefully, it seems to be quite permanent).
// @see {@link https://support.google.com/analytics/answer/12226705?hl=en}
"idProductsQuery","searchDate","idSearchEntity","idSearchType",// `searchText` would have been many times over the threshold
// when we had free text search; now we don't, so it's fine
// and can be kept as a custom dimension in the `GA4` dashboard.
"searchText","idServices","priceMax","priceMin","starsMax","starsMin","checkIn","checkOut","distance","direction","field",// Remember that `placeId` is not created as a custom dimension
// in the `GA4` dashboard.
"placeId",// Search context: end.
"hotelId","isNoResults","dbhVersionCode","dbhBuildDate","dbhGitCommit","isDbhStaff","deviceBreakpoint","isFirstPageAfterSsr","locationHash","originFromServer","navigatorConnectionEffectiveType","adminPageReference","isUserSignedIn","isStaticPage","daybreakHeroSubDomain","daybreakHeroPartnerId","isPageServedFromCache","reactRouterPagePath","windowPagePath","navigatorConnectionDownlink","page_location","page_title","content_group","page_type","currentSiteCountry","fromPageLocation","toPageLocation","activeConsentGroups","orderDirection","orderField","acceptsDiscountCodes",...(e?["anonymousId","guidTab","guidSession"]:[]),"lat","lng"].filter(Boolean))).toObject(),...(i?{items:i.toJS()}:{}),...(j?{coupon:j}:{}),// @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#track_purchases}
...(h?{transaction_id:h}:{}),...("timing_complete"===g?{name:a.get("userTimingName")}:{})};// If you send a null value for a parameter, `GA4` will record it as the
// string "null". This can create unnecessary clutter in your reports,
// forcing you to deal with a meaningless literal value. It does not
// provide additional analytical value. `undefined` values are instead
// omitted by `GA4`. We exclude both.
l=Object.fromEntries(Object.entries(l).filter(a=>{let[,b]=a;return ![null,void 0].includes(b)}));const m={eventPastTense:g,stgtmGa4eventData:l};k?(canLog$1()&&console.info("[dbh][gtag][event] Added to send later queue:",m),QUEUED_EVENTS.push(m),c()):sendEventWithGtagAndLogs$1(m);}));return a},analyticsEventSent=isServerSideRendering()?_noop:createAnalyticsEventSent();// @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p344388821/admin/customdefinitions/hub}
// @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p326658850/admin/customdefinitions/hub}
var analyticsEventSent$1 = analyticsEventSent;

/**
 * Extracts from the `localStorage` the current value of the key: `isDbhStaff`.
 * @return {string} Either "true" or "false".
 */const getAnalyticsDimensionIsDbhStaff=()=>{let a="false";try{a=getStorageItem("isDbhStaff")?"true":"false";}catch(a){/* Ignore any possible exception. */}return a};var getAnalyticsDimensionIsDbhStaff$1 = getAnalyticsDimensionIsDbhStaff;

/**
 * Returns a selector that returns the generic custom dimensions and metrics, to
 * be sent with the `window.gtag` `GA4` events.
 * @return {Object} .
 */const makeSelectGtagDataToSendWithEveryEvent=withConformsTo("makeSelectGtagDataToSendWithEveryEvent",["isFirstGtagConfigInBrowser",PropTypes.bool],a=>createSelector(makeSelectCurrentRouterState(),makeSelectCurrentWebpageRouteReference(),makeSelectCurrentWebpageRouteTemplateCode(),makeSelectIsUserSignedIn(),makeSelectIsStaticPage(),selectLocationOriginFromServer,makeSelectComputedSSrBreakpointsForRendering(),makeSelectDaybreakHeroData(),makeSelectCountry(),makeSelectTemplateCode(),makeSelectTitle(),makeSelectGeneratedTrackingIds(),makeSelectSearchContext(),(b,c,d,e,f,g,h,i,j,k,l,m,n)=>{let{locationBeforeTransitions:{pathname:o,search:p,hash:q}}=b;const r=isServerSideRendering(),s=isServerSideRendering()?void 0:getAnalyticsDimensionIsDbhStaff$1(),t=h.get("breakpoint");let u="";const v=d===PAGE_TYPES.TopMenuHashPage;let w=k;v&&(u=q,w=PAGE_TYPES.TopMenuHashPage);const{navigatorConnectionEffectiveType:x,navigatorConnectionDownlink:y}=r?{}:extractConnectionDataFromNavigator(),z=i.get("subDomain"),A=i.get("partnerId"),B=A?A+"":null,C=r?void 0:window.location.pathname+window.location.search+window.location.hash,D=""+o+p+u,E=n?searchContextToGaEventParameters(n):{},F={dbhVersionCode:"2025-01-16T14_48_05-efb479c8536e2481004cd382dca677dfccc3fd85-production-bitbucket-8628",dbhBuildDate:"2025-01-16T14_48_05",dbhGitCommit:"efb479c8536e2481004cd382dca677dfccc3fd85",...(isServerSideRendering()?{}:{isDbhStaff:s}),deviceBreakpoint:t,// We always set this to `false` because if we use the selector, it
// could return `true` if the user goes back to the initial landing page,
// and we don't want that.
isFirstPageAfterSsr:a?"true":"false",locationHash:u||void 0,originFromServer:g,navigatorConnectionEffectiveType:x,// @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webpages?templateCode=TopMenuHashPage&_fields=url,id,templateCode,reference&_makeUrlRelative=true}
adminPageReference:c,isUserSignedIn:e?"true":"false",isStaticPage:f?"true":"false",daybreakHeroSubDomain:z||void 0,daybreakHeroPartnerId:B||void 0,// We always set this to `false` because if we use the selector, it could
// return `true` if the user goes back to the initial, cached landing
// page, and we don't want that.
isPageServedFromCache:"false",reactRouterPagePath:D,windowPagePath:C,navigatorConnectionDownlink:y?y+"":void 0,currentSiteCountry:j,...(a?{isFirstPageViewEventForCurrentSiteCountry:"true"}:{}),// Only For `GA4`.
// @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/config#content_group}
content_group:w,page_type:w,// @see {@link https://stackoverflow.com/questions/64966221/google-analytics-4-rogue-referral-on-spa-and-referral-exclusion}
page_location:""+g+o+p,page_title:l,...m,...(E?{...E}:{})},G=Object.fromEntries(Object.entries(F).filter(a=>{let[,b]=a;return ![null,void 0].includes(b)}));// Production account:
// @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p326658850/admin/customdefinitions/hub}
// Development account:
// @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p344388821/admin/customdefinitions/hub}
// If you send a null value for a parameter, `GA4` will record it as the
// string "null". This can create unnecessary clutter in your reports,
// forcing you to deal with a meaningless literal value. It does not
// provide additional analytical value. `undefined` values are instead
// omitted by `GA4`. We exclude both.
return G}));var makeSelectGtagDataToSendWithEveryEvent$1 = makeSelectGtagDataToSendWithEveryEvent;

const makeSelectGtagDataToSendWithEveryEventAsMap=()=>createSelector(makeSelectGtagDataToSendWithEveryEvent$1(),Map);var makeSelectGtagDataToSendWithEveryEventAsMap$1 = makeSelectGtagDataToSendWithEveryEventAsMap;

/**
 * Returns a callback that sends a `GA4` event with `gtag`, and
 * returns a Promise.
 * @return {Promise} .
 */const useAnalyticsEventSent=()=>{const a=useMakeSelect(makeSelectGtagDataToSendWithEveryEventAsMap$1),b=useCallback(b=>analyticsEventSent$1(a.merge(b)),[a]);return b};var useAnalyticsEventSent$1 = useAnalyticsEventSent;

// always overwrites data inside `gtagDataToSendWithEveryEvent`.
const handleAnalyticsEventSent=withConformsTo("handleAnalyticsEventSent",[],function*(a,b){const c=yield select(makeSelectGtagDataToSendWithEveryEventAsMap$1());analyticsEventSent$1(c.merge(a),b);});var handleAnalyticsEventSent$1 = handleAnalyticsEventSent;

export { analyticsEventImmutablePropType$1 as analyticsEventImmutablePropType, analyticsEventSent$1 as analyticsEventSent, eventNamePropType$1 as eventNamePropType, getAnalyticsDimensionIsDbhStaff$1 as getAnalyticsDimensionIsDbhStaff, handleAnalyticsEventSent$1 as handleAnalyticsEventSent, makeSelectGtagDataToSendWithEveryEvent$1 as makeSelectGtagDataToSendWithEveryEvent, makeSelectGtagDataToSendWithEveryEventAsMap$1 as makeSelectGtagDataToSendWithEveryEventAsMap, useAnalyticsEventSent$1 as useAnalyticsEventSent };
