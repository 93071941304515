import { fromJS } from 'immutable';
import { API_IDS_TO_VERSIONS } from '@dbh/api-constants';
export { R as REDUX_AND_SAGA_KEY } from './index-d29659f9.js';
import '@dbh/reselect-extra';
import 'reselect';
import '@dbh/ssr-data-redux';
import 'universal-cookie';
import '@dbh/environment';
import '@dbh/cookie-keys';
import '@dbh/cookies';
import 'lodash/once';
import 'prop-types';
import '@dbh/domain-utils';
import '@dbh/with-conforms-to-for-production-www';
import 'invariant';
import '@dbh/admindev-constants';
import '@dbh/routing-redux';
import '@dbh/make-api-url';

const initialState=fromJS({apiIdsToVersions:API_IDS_TO_VERSIONS}),configurationReducer=function(a){return void 0===a&&(a=initialState),a};

export { configurationReducer as default };
